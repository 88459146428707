<template>
  <div>
    <a-drawer
        title="修改"
        placement="right"
        :closable="false"
        :visible="listBox.isShow"
        @close="addClose"
        width="500"
    >
      <div class="addForms">
        <a-form-model
            ref="addRuleForm"
            :model="listBox.addForm"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
          <a-form-model-item label="身份" prop="type">
            <a-select
                show-search
                option-filter-prop="children"
                @change="selectNumberType"
                placeholder="请选择"
                v-model="listBox.addForm.type"
                style="width: 300px">
              <a-select-option value="1001">
                医生
              </a-select-option>
              <a-select-option value="1002">
                护士
              </a-select-option>
              <a-select-option value="1003">
                医技
              </a-select-option>
              <a-select-option value="1004">
                药师
              </a-select-option>
              <a-select-option value="1005">
                学生
              </a-select-option>
              <a-select-option value="1006">
                其他医务从业者
              </a-select-option>
              <a-select-option value="1007">
                非医务从业者
              </a-select-option>
              <a-select-option value="0">
                身份不明人员
              </a-select-option>
              <!-- <a-select-option value="1008">
                内部人员
              </a-select-option> -->
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="姓名" prop="name">
            <a-input style="width: 300px" v-model="listBox.addForm.name" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="tel">
            <a-input style="width: 300px" v-model="listBox.addForm.tel" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item
              label="省份/城市" prop="province">
            <a-cascader
                style="width: 300px"
                v-model="listBox.addForm.province"
                :options="hospitaloPtions"
                placeholder="请选择"
                @change="provinceChange"
            />
          </a-form-model-item>
          <a-form-model-item
              v-if="listBox.addForm.type === '1001' || listBox.addForm.type === '1002' || listBox.addForm.type === '1003' ||listBox.addForm.type === '1004'"
              label="医院" prop="hospitalName">
            <a-select
                @popupScroll="popupScrollHos"
                @search="selectHos"
                style="width: 300px"
                v-model="listBox.addForm.hospitalName"
                placeholder="请选择"
                @change="hospitalSeleChange">
              <a-select-option
                  v-for="item in hospitalNameList"
                  :value="item.name">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
              v-if="listBox.addForm.type === '1001' || listBox.addForm.type === '1002' || listBox.addForm.type === '1003' ||listBox.addForm.type === '1004'"
              label="科室" prop="hospitalOffices">
            <a-cascader
                style="width: 300px"
                v-model="listBox.addForm.hospitalOffices"
                :options="hospitalOfficesList"
                placeholder="请选择"
                @change="officeChange" />
          </a-form-model-item>
          <a-form-model-item
              v-if="listBox.addForm.type === '1001' || listBox.addForm.type === '1002' || listBox.addForm.type === '1003' ||listBox.addForm.type === '1004'"
              label="职称" prop="jobTitle">
            <a-select
                style="width: 300px"
                v-model="listBox.addForm.jobTitle"
                placeholder="请选择"
                @change="jobTitleChange">
              <a-select-option
                  v-for="item in jobTitleList"
                  :value="item.jobTitle">
                {{item.jobTitle}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-------------------------------1005------------------------------------------->
          <a-form-model-item v-if="listBox.addForm.type === '1005'" label="学院" prop="school">
            <a-select
                @popupScroll="popupScrollSchool"
                @search="selectSchool"
                style="width: 300px"
                v-model="listBox.addForm.school"
                placeholder="请选择"
                @change="schoolChange">
              <a-select-option
                  v-for="item in schoolListData"
                  :value="item.name">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="listBox.addForm.type === '1005'" label="学历" prop="education">
            <a-select style="width: 300px" placeholder="请选择" v-model="listBox.addForm.education"
                      @change="educationChange">
              <a-select-option
                  v-for="item in educationList"
                  :value="item.education">
                {{item.education}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="listBox.addForm.type === '1005'" label="专业" prop="specialty">
            <a-input style="width: 300px" v-model="listBox.addForm.specialty" placeholder="请输入" />
          </a-form-model-item>
          <!-------------------------------1006  ~  1007------------------------------------------->
          <a-form-model-item
              v-if="listBox.addForm.type === '1006' || listBox.addForm.type === '1007'"
              label="单位" prop="workUnit">
            <a-input style="width: 300px" v-model="listBox.addForm.workUnit" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item
              v-if="listBox.addForm.type === '1006' || listBox.addForm.type === '1007'"
              label="职务" prop="post">
            <a-input  style="width: 300px" v-model="listBox.addForm.post" placeholder="请输入" />
          </a-form-model-item>

          <!--      新增时共有的        -->
          <a-form-model-item label="性别">
            <a-select style="width: 300px" placeholder="请选择" v-model="listBox.addForm.sex">
              <a-select-option
                  v-for="item in sexList"
                  :value="item.value">
                {{item.sex}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
              label="邮箱">
            <a-input style="width: 300px" v-model="listBox.addForm.email" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item
              label="资格证书编码"   v-if="listBox.addForm.type !== '0'">
            <a-input style="width: 300px" v-model="listBox.addForm.doctorNum" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item
              label="生日">
            <a-date-picker :allowClear="false" @change="changeBirthday" placeholder="请选择" style="width: 300px" v-model="listBox.addForm.birthday" />
          </a-form-model-item>
          <a-form-model-item label="认证状态">

            <span v-if="listBox.addForm.certStatus === 'UNCERTIFIED'">未认证</span>
            <a v-if="listBox.addForm.certStatus === 'IN_REVIEW'" style="color: orange" @click="openNotificationBotton('topLeft')" >待审核，点击审核</a>
            <a v-if="listBox.addForm.certStatus === 'CERTIFIED'" @click="openNotification('topLeft')">已认证</a>

          </a-form-model-item>
          <a-form-model-item
              label="注册渠道">
            {{listBox.addForm.registerChannel === null || listBox.addForm.registerChannel === '' || listBox.addForm.registerChannel === undefined ? '未知':listBox.addForm.registerChannel}}
            <!--            <a-input :disabled="true" style="width: 300px;margin-bottom: 20px" v-model="addForm.registerChannel" placeholder="请输入" />-->
          </a-form-model-item>
        </a-form-model>
      </div>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmit">
          确认
        </a-button>
      </div>
    </a-drawer>

    <a-drawer
        width="500"
        title="驳回原因"
        placement="right"
        :closable="false"
        :visible="rejectedShow"
        @close="handleCancel"
    >
      <a-textarea v-model="remark" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
      <span v-if="remarkDetail" style="color: red;margin-top: 5px">请输入</span>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="handleCancel">
          取消
        </a-button>
        <a-button type="primary" @click="rejectedOk">
          确认
        </a-button>
      </div>

    </a-drawer>

    <!-- 更新资料弹窗 -->
    <a-modal
      v-model="modaLshow"
      title="位置修改填写"
      @ok="handleOk"
      @cancel="modalCancel"
    >
      <a-form-model
        :label-col="{ span:  6}"
        :wrapper-col="{ span: 18 }"
        :rules="editRules"
        ref="editForm"
        :model="editForm"
      >
      <span style="font-size:13px;"> 示例：如专家原医院由山东省立医院改为齐鲁医院，内容则输入：齐鲁医院 </span>
        <!-- <span style="font-size:13px;"> 示例：如专家原医院由山东省立医院改为齐鲁医院<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 内容则输入：齐鲁医院 </span> -->
        <a-form-model-item label="填写修改后内容" prop="laterContent" style="margin-top:20px">
          <!-- <a-input v-model="editForm.laterContent" placeholder="请输入"> </a-input> -->
          <a-textarea v-model="editForm.laterContent" auto-size placeholder="请输入" style="width:100%;min-height: 120px;font-size: 16px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

<!--    <a-modal style="z-index: 99999999!important;" v-model="rejectedShow"-->
<!--             title="驳回原因" okType="danger" okText="驳回" @ok="rejectedOk"  @cancel="handleCancel">-->
<!--      <a-textarea v-model="remark"></a-textarea>-->
<!--      <span v-if="remarkDetail" style="color: red;margin-top: 5px">请输入</span>-->
<!--    </a-modal>-->
  </div>
</template>

<script>
import City from "@/assets/city.json";
import { getMemberOneList, putMember} from "@/service/MedicalConference_y";
import {getTitle} from "@/service/dataDictionary_api";
import {getHospital} from "@/service/hospital_api";
import {getSchool} from "@/service/school_api";
import {getLoginDepartment} from "@/service/dataCleaned_api";
import {getAuditId, getCaseExportBulk, putAudit,postMember} from "@/service/memberImport_api";
import moment from "moment";
export default {
  name:'ListPackaging',
  props: ["cmovies"],
  data(){
    return{
      editForm:{
        laterContent:""
      },
      modaLshow:false,
      rejectedShow:false,
      remarkDetail:false,
      remark:'',
      writeBackImg:require('../../../assets/img_3.png'),
      auditForm:{

      },
      visible:false,
      City:City,
      addVisible:false,
      // addForm:{
      //   type:'1001',
      //   name:'',
      //   tel:'',
      //   province:undefined,
      //   city:'',
      //   hospitalName:undefined,
      //   hospitalOffices:undefined,
      //   jobTitle:undefined,
      //   school:undefined,
      //   education:undefined,
      //   specialty:'',
      //   workUnit:'',
      //   post:'',
      //   doctorNum:'',
      //   sex:undefined,
      //   email:'',
      //   birthday:'',
      //   certStatus:'未认证',
      //   registerChannel: '后台添加',
      // },
      editRules:{
        laterContent: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      rules:{},
      //1001-1004
      rules1:{
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        hospitalName: [{ required: true, message: '请选择', trigger: 'change' }],
        hospitalOffices: [{ required: true, message: '请选择', trigger: 'change' }],
        jobTitle: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      //1005
      rules2:{
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        school: [{ required: true, message: '请选择', trigger: 'change' }],
        education: [{ required: true, message: '请选择', trigger: 'change' }],
        specialty: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      //1006-1007
      rules3:{
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        workUnit: [{ required: true, message: '请输入', trigger: 'change' }],
        post: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      //0
      rules4:{
        type: [{ required: true, message: '请选择', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'change' }],
        tel: [{ required: true, message: '请输入', trigger: 'change' }],
        // province: [{ required: true, message: '请选择', trigger: 'change' }],
        // workUnit: [{ required: true, message: '请输入', trigger: 'change' }],
        // post: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      hospitaloPtions: [],
      hospitalNameList:[],
      hospitalNameList_pageNo:1,
      schoolNameList_pageNo:1,
      hosTitle:'',
      schTitle:'',
      hospitalOfficesList:[],
      jobTitleList:[],
      schoolListData:[],
      educationList:[
        {
          id:'1',
          education:'初中'
        },
        {
          id:'2',
          education:'高中'
        },
        {
          id:'3',
          education:'专科'
        },
        {
          id:'4',
          education:'本科'
        },
        {
          id:'5',
          education:'研究生'
        },
      ],
      sexList:[
        {
          id:1,
          sex:'男',
          value:1,
        },{
          id:2,
          sex:'女',
          value:2,
        },{
          id:3,
          sex:'未知',
          value:0,
        },
      ],
      reg:RegExp(/-/),
      pageNo:1,
      isShow:false,
    }
  },
  created() {
    this.getHospitalOfficesList()
    this.getJobTitleList(this.listBox.addForm.type,1)
  },
  //监听
  watch:{
    listBoxIsShow(newVal){
        if(newVal){
          // console.log(newVal)
          this.getListOne(this.listBox.memberId)
        }
    }
  },
  computed:{
  //  vuex-名单对话框数据
    listBox(){
      return this.$store.state.listDialogBox
    },
    listBoxIsShow(){
      return this.$store.state.listDialogBox.isShow
    },
  },
  mounted() {
    this.rules = this.rules1
    this.getPCH()
    // this.getRules()
  },
  methods:{
    async handleOk(){
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          //  1 提交表单内容
          let form = this.listBox.addForm
          let data = {};
          switch (form.type){
            case '1001':{
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,

                "province": form.province[0],
                "city": form.province[1],

                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
                "jobTitle": form.jobTitle,

                "doctorNum":form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl":'',
              }
              break;
            }
            case '1002':{
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
                "jobTitle": form.jobTitle,

                "province": form.province[0],
                "city": form.province[1],

                "doctorNum":form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl":'',
              }
              break;
            }
            case '1003':{
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
                "jobTitle": form.jobTitle,

                "province": form.province[0],
                "city": form.province[1],

                "doctorNum":form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl":'',
              }
              break;
            }
            case '1004':{
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "hospitalName": form.hospitalName,
                "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
                "jobTitle": form.jobTitle,

                "province": form.province[0],
                "city": form.province[1],

                "doctorNum":form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl":'',
              }
              break;
            }
            case '1005':{
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "school": form.school,
                "education": form.education,
                "specialty": form.specialty,

                "province": form.province[0],
                "city": form.province[1],

                "doctorNum":form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl":'',
              }
              break;
            }
            case '1006':{
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "workUnit": form.workUnit,
                "post": form.post,

                "province": form.province[0],
                "city": form.province[1],

                "doctorNum":form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "birthday": form.birthday,
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl":'',
              }
              break;
            }
            case '1007':{
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                "workUnit": form.workUnit,
                "post": form.post,

                "province": form.province[0],
                "city": form.province[1],

                "doctorNum":form.doctorNum,
                "sex": form.sex,
                "email": form.email,
                "birthday": form.birthday,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl":'',
              }
              break;
            }
            case '0':{
              data = {
                "type": form.type,
                "name": form.name,
                "tel": form.tel,
                // "workUnit": form.workUnit,
                // "post": form.post,

                "province": form.province[0],
                "city": form.province[1],

                // "doctorNum":form.doctorNum,  //不传资格证书
                "sex": form.sex,
                "email": form.email,
                "birthday": form.birthday,
                // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
                "certStatus": form.certStatus,
                "registerChannel": form.registerChannel,
                "nvqUrl":'',
              }
              break;
            }
            default:{
              break;
            }
          }
          await this.putList(this.listBox.memberId,data)

          // 2.提交修改内容
          const updateData = {
            laterContent:this.editForm.laterContent,
            memberId:this.listBox.memberId
          }
          // console.log("检查data",updateData,data)
          await this.postUpdateMember(updateData)
        }else{
          return false;
        }

      })

    },
    //  更新修改内容
    async postUpdateMember(data) {
      const response = await postMember(data)
      if(response.code === 0){
        //清空--关闭弹窗和表单
        this.addClose()
        this.modaLshow = false
        this.editForm.laterContent = ''
        this.$message.success("填写成功~")
      }else {
        this.$message.warning(response.message)
      }
    },
    modalCancel(){
      this.modaLshow = false
    },
    // 回显校验规则
    getRules(){
      // console.log("查看回显",this.listBox.addForm.type)
      switch (this.listBox.addForm.type){
        case '1001':{
          this.rules = this.rules1
          break;
        }
        case '1002':{
          this.rules = this.rules1
          break;
        }
        case '1003':{
          this.rules = this.rules1
          break;
        }
        case '1004':{
          this.rules = this.rules1
          break;
        }
        case '1005':{
          this.rules = this.rules2
          break;
        }
        case '1006':{
          this.rules = this.rules3
          break;
        }
        case '1007':{
          this.rules = this.rules3
          break;
        }
        case '0':{
          this.rules = this.rules4
          break;
        }
        default:{
          break;
        }
      }
    },
    //获取省份城市医院
    getPCH(){
      let province = []
      let hospitalName = []
      this.City.forEach((item,index)=>{
        let city = []
        province.push({
          value:item.name,
          label:item.name,
          children:city
        })
        item.cityList.forEach((list)=>{
          city.push({
            value:list.name,
            label:list.name,
            loading:false,
          })
        })
        province[index].children = city
      })
      this.hospitaloPtions = province
      this.schoolPtions = province
    },
    addClose(){
      this.listBox.isShow = false
      this.listBox.memberId = null
      // // this.addVisible = false
      this.hospitalNameList = []
      this.schoolListData = []
      this.hospitalNameList_pageNo = 1
      this.schoolNameList_pageNo = 1
      this.$refs.addRuleForm.resetFields()

      let form = this.listBox.addForm
      form.birthday = undefined
      form.sex = undefined
      form.doctorNum = ''
      form.email = ''
      form.certStatus = '未认证'
      form.registerChannel = '后台添加'
    },
    selectNumberType(){
      this.$set(this.listBox.addForm,'jobTitle',undefined)
      //其他医务从业者--医务从业者不需要选择省份城市
      if(this.listBox.addForm.type !== '1006' || this.listBox.addForm.type !== '1007'){
        this.getJobTitleList(this.listBox.addForm.type,1)
      }
      //选择身份时切换rules校验
      switch (this.listBox.addForm.type){
        case '1001':{
          this.rules = this.rules1
          break;
        }
        case '1002':{
          this.rules = this.rules1
          break;
        }
        case '1003':{
          this.rules = this.rules1
          break;
        }
        case '1004':{
          this.rules = this.rules1
          break;
        }
        case '1005':{
          this.rules = this.rules2
          break;
        }
        case '1006':{
          this.rules = this.rules3
          break;
        }
        case '1007':{
          this.rules = this.rules3
          break;
        }
        case '0':{
          this.rules = this.rules4
          break;
        }
        default:{
          break;
        }
      }
    },
    provinceChange(value) {
      this.listBox.addForm.school = undefined  //清空之前选择的学院
      this.hospitalNameList = []
      this.schoolListData = []
      this.hospitalNameList_pageNo = 1
      this.schoolNameList_pageNo = 1
      if(this.listBox.addForm.type === '1001' ||
          this.listBox.addForm.type === '1002' ||
          this.listBox.addForm.type === '1003' ||
          this.listBox.addForm.type === '1004'
      ){
        let data = {
          province:value[0],
          city:value[1],
          pagenum:this.hospitalNameList_pageNo
        }
        this.getHosNameList(data)
      }else if(this.listBox.addForm.type === '1005'){
        let data = {
          province:value[0],
          city:value[1],
          pagenum:this.schoolNameList_pageNo
        }
        this.getschoolList(data)
      }
      // else if(this.listBox.addForm.type === '1006' ||
      //     this.listBox.addForm.type === '1007'){
      //
      // }
    },
    //获取职称列表
    async getJobTitleList(identify,pageNum,title) {
      //type:identify,pageNum,title
      const response = await getTitle(identify,pageNum,title)
      if (response.code === 0) {
        this.jobTitleList = response.data.row
        console.log('1111111111',this.jobTitleList)
      }
    },
    //获取医院列表
    async getHosNameList(data) {
      const response = await getHospital(data)
      if(response.code === 0){
        // this.hospitalNameList = response.data.rows
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.hospitalNameList.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },
    //滚动条监听
    popupScrollHos(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.hospitalNameList_pageNo++
        let data = {
          province:this.listBox.addForm.province[0],
          city:this.listBox.addForm.province[1],
          pagenum:this.hospitalNameList_pageNo,
          name:this.hosTitle
        }
        this.getHosNameList(data)
      }
    },
    selectHos(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.hospitalNameList = []
      this.hosTitle = value
      let data = {
        province:this.listBox.addForm.province[0],
        city:this.listBox.addForm.province[1],
        name:this.hosTitle
      }
      this.getHosNameList(data)
    },
    //获取学院列表
    async getschoolList(data) {
      const response = await getSchool(data)
      if(response.code === 0){
        // this.schoolListData = response.data.rows
        let list  = response.data.rows
        for (let i=0;i<list.length;i++){
          this.schoolListData.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },
    //滚动条监听
    popupScrollSchool(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.schoolNameList_pageNo++
        let data = {
          province:this.listBox.addForm.province[0],
          city:this.listBox.addForm.province[1],
          pagenum:this.schoolNameList_pageNo,
          name:this.schTitle
        }
        this.getschoolList(data)
      }
    },
    selectSchool(value) {
      //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.schoolListData = []
      this.hosTitle = value
      let data = {
        province:this.listBox.addForm.province[0],
        city:this.listBox.addForm.province[1],
        name:this.schTitle
      }
      this.getschoolList(data)
    },
    hospitalSeleChange(){

    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment()
      if(response.code === 0){
        let oneOff = []
        let list = response.data.wySy
        list.forEach((item,index)=>{
          let twoOff = []
          oneOff.push({
            value:item.title,
            label:item.title,
            children:twoOff
          })
          item.zoffices.forEach((lists)=>{
            twoOff.push({
              value:lists.title,
              label:lists.title,
            })
          })
          oneOff[index].children = twoOff
        })
        this.hospitalOfficesList = oneOff
      }
    },
    jobTitleChange(value) {
      console.log(value);
    },
    officeChange(value){
      console.log(value);
    },
    schoolChange(){

    },
    educationChange(){

    },
    onSubmit(){
      this.$refs.addRuleForm.validate(async valid => {
        if (valid) {
          this.modaLshow = true


/////////////////分割线


          // let form = this.listBox.addForm
          // let data = {};
          // switch (form.type){
          //   case '1001':{
          //     data = {
          //       "type": form.type,
          //       "name": form.name,
          //       "tel": form.tel,

          //       "province": form.province[0],
          //       "city": form.province[1],

          //       "hospitalName": form.hospitalName,
          //       "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
          //       "jobTitle": form.jobTitle,

          //       "doctorNum":form.doctorNum,
          //       "sex": form.sex,
          //       "email": form.email,
          //       // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
          //       "birthday": form.birthday,
          //       "certStatus": form.certStatus,
          //       "registerChannel": form.registerChannel,
          //       "nvqUrl":'',
          //     }
          //     break;
          //   }
          //   case '1002':{
          //     data = {
          //       "type": form.type,
          //       "name": form.name,
          //       "tel": form.tel,
          //       "hospitalName": form.hospitalName,
          //       "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
          //       "jobTitle": form.jobTitle,

          //       "province": form.province[0],
          //       "city": form.province[1],

          //       "doctorNum":form.doctorNum,
          //       "sex": form.sex,
          //       "email": form.email,
          //       // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
          //       "birthday": form.birthday,
          //       "certStatus": form.certStatus,
          //       "registerChannel": form.registerChannel,
          //       "nvqUrl":'',
          //     }
          //     break;
          //   }
          //   case '1003':{
          //     data = {
          //       "type": form.type,
          //       "name": form.name,
          //       "tel": form.tel,
          //       "hospitalName": form.hospitalName,
          //       "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
          //       "jobTitle": form.jobTitle,

          //       "province": form.province[0],
          //       "city": form.province[1],

          //       "doctorNum":form.doctorNum,
          //       "sex": form.sex,
          //       "email": form.email,
          //       // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
          //       "birthday": form.birthday,
          //       "certStatus": form.certStatus,
          //       "registerChannel": form.registerChannel,
          //       "nvqUrl":'',
          //     }
          //     break;
          //   }
          //   case '1004':{
          //     data = {
          //       "type": form.type,
          //       "name": form.name,
          //       "tel": form.tel,
          //       "hospitalName": form.hospitalName,
          //       "hospitalOffices": form.hospitalOffices[0]+'-'+form.hospitalOffices[1],
          //       "jobTitle": form.jobTitle,

          //       "province": form.province[0],
          //       "city": form.province[1],

          //       "doctorNum":form.doctorNum,
          //       "sex": form.sex,
          //       "email": form.email,
          //       // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
          //       "birthday": form.birthday,
          //       "certStatus": form.certStatus,
          //       "registerChannel": form.registerChannel,
          //       "nvqUrl":'',
          //     }
          //     break;
          //   }
          //   case '1005':{
          //     data = {
          //       "type": form.type,
          //       "name": form.name,
          //       "tel": form.tel,
          //       "school": form.school,
          //       "education": form.education,
          //       "specialty": form.specialty,

          //       "province": form.province[0],
          //       "city": form.province[1],

          //       "doctorNum":form.doctorNum,
          //       "sex": form.sex,
          //       "email": form.email,
          //       // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
          //       "birthday": form.birthday,
          //       "certStatus": form.certStatus,
          //       "registerChannel": form.registerChannel,
          //       "nvqUrl":'',
          //     }
          //     break;
          //   }
          //   case '1006':{
          //     data = {
          //       "type": form.type,
          //       "name": form.name,
          //       "tel": form.tel,
          //       "workUnit": form.workUnit,
          //       "post": form.post,

          //       "province": form.province[0],
          //       "city": form.province[1],

          //       "doctorNum":form.doctorNum,
          //       "sex": form.sex,
          //       "email": form.email,
          //       // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
          //       "birthday": form.birthday,
          //       "certStatus": form.certStatus,
          //       "registerChannel": form.registerChannel,
          //       "nvqUrl":'',
          //     }
          //     break;
          //   }
          //   case '1007':{
          //     data = {
          //       "type": form.type,
          //       "name": form.name,
          //       "tel": form.tel,
          //       "workUnit": form.workUnit,
          //       "post": form.post,

          //       "province": form.province[0],
          //       "city": form.province[1],

          //       "doctorNum":form.doctorNum,
          //       "sex": form.sex,
          //       "email": form.email,
          //       "birthday": form.birthday,
          //       // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
          //       "certStatus": form.certStatus,
          //       "registerChannel": form.registerChannel,
          //       "nvqUrl":'',
          //     }
          //     break;
          //   }
          //   case '0':{
          //     data = {
          //       "type": form.type,
          //       "name": form.name,
          //       "tel": form.tel,
          //       // "workUnit": form.workUnit,
          //       // "post": form.post,

          //       "province": form.province[0],
          //       "city": form.province[1],

          //       // "doctorNum":form.doctorNum,  //不传资格证书
          //       "sex": form.sex,
          //       "email": form.email,
          //       "birthday": form.birthday,
          //       // "birthday": moment(form.birthday).format('YYYY-MM-DD'),
          //       "certStatus": form.certStatus,
          //       "registerChannel": form.registerChannel,
          //       "nvqUrl":'',
          //     }
          //     break;
          //   }
          //   default:{
          //     break;
          //   }
          // }
          // await this.putList(this.listBox.memberId,data)
          // //清空--关闭
          // this.addClose()



/////////////////分割线
        } else {
          return false;
        }
      });
    },
    //  修改
    async putList(id,data) {
      const response = await putMember(id,data)
      if(response.code === 0){
        this.$message.success("修改成功~")
      }else {
        this.$message.warning(response.message)
      }
    },
    //获取单个
    async getListOne(memberId) {
      // let data = {
      //   id:memberId,
      // }
      // const response = await getMemberList(data)
      const response = await getMemberOneList(memberId)
      if(response.code === 0){
        //  赋值
        this.listBox.addForm = response.data[0]
        console.log("表单", response.data[0])
        // 规则校验的回显
        switch (response.data[0].type){
        case '1001':{
          this.rules = this.rules1
          break;
        }
        case '1002':{
          this.rules = this.rules1
          break;
        }
        case '1003':{
          this.rules = this.rules1
          break;
        }
        case '1004':{
          this.rules = this.rules1
          break;
        }
        case '1005':{
          this.rules = this.rules2
          break;
        }
        case '1006':{
          this.rules = this.rules3
          break;
        }
        case '1007':{
          this.rules = this.rules3
          break;
        }
        case '0':{
          this.rules = this.rules4
          break;
        }
        default:{
          break;
        }
      }



        this.listBox.addForm.province = [response.data[0].province,response.data[0].city]
        if(this.listBox.addForm.hospitalOffices){
          this.listBox.addForm.hospitalOffices = response.data[0].hospitalOffices.split('-')
        }

        //  加载医院与学院
        if(this.listBox.addForm.province){

          let data = {
            province:this.listBox.addForm.province[0],
            city:this.listBox.addForm.province[1],
            pagenum:this.hospitalNameList_pageNo
          }
          this.getHosNameList(data)
          let data2 = {
            province:this.listBox.addForm.province[0],
            city:this.listBox.addForm.province[1],
            pagenum:this.schoolNameList_pageNo
          }
          this.getschoolList(data2)
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    //打开驳回对话框
    inReview(){
      this.rejectedShow = true
    },
    changeBirthday(date, dateString) {
      this.listBox.addForm.birthday = moment(this.listBox.addForm.birthday).format('YYYY-MM-DD')
    },
    returnBtn(){
      window.history.back()
    },
    async openNotification(placement) {
        const Info = await getAuditId(this.listBox.memberId)
      // if (Info.data) {
      //   this.auditForm = Info.data[0]
      // }
      this.$notification.open({
        message: `认证材料`,
        description:
            <div style="height:600px;overflow-y:scroll">
              <div>
                <img src={`${Info.data[0].employeeCardUrl ? Info.data[0].employeeCardUrl:this.writeBackImg}`} style='max-width:500px;height:auto;'/>
              </div>
              <div>
                <img src={`${Info.data[0].practiceLicenseUrl ? Info.data[0].practiceLicenseUrl : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
              </div>
              <div>
                <img src={`${Info.data[0].nvqUrl ? Info.data[0].nvqUrl : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
              </div>

            </div>,
        style:{
          "width":"600px",
          "min-height":"400px"
        },
        placement,
        duration: 0,
      });
    },
    async openNotificationBotton(placement) {
      const Info = await getAuditId(this.listBox.memberId)
      this.$notification.open({
        message: `认证材料`,
        description:
           <div>
             <div style="height:600px;overflow-y:scroll;">
               <div>
                 <img src={`${Info.data[0].nvqUrl ? Info.data[0].nvqUrl : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
               </div>
               <div>
                 <img src={`${Info.data[0].employeeCardUrl ? Info.data[0].employeeCardUrl:this.writeBackImg}`} style='max-width:500px;height:auto;'/>
               </div>
               <div>
                 <img src={`${Info.data[0].practiceLicenseUrl ? Info.data[0].practiceLicenseUrl : this.writeBackImg}`} style='max-width:500px;height:auto;'/>
               </div>
             </div>
           </div>,
        btn: h => {
          return h('div', null, [
            h('a-button',
            {
              props: {
                type: 'danger',
                // type: 'primary',
                // size: 'small',
              },
              on: {
                click: () => {this.inReview()},
              },
            },
            '驳回申请',),
            h('a-button',
                {
                  props: {
                    type: 'primary',
                  },
                  attrs: {
                    style: 'margin: 0 0 0 10px;'
                  },
                  on: {
                    click: () => {this.agreedBtn()},
                  },
                },
                '审核通过',),
        ])
        },

        style:{
          "width":"600px",
          "min-height":"400px",
          "z-index":'999'
        },
        placement,
        duration: 0,
      });
    },
    //驳回
    async rejectedOk() {
      if (this.remark) {
        let data = {
          remark: this.remark,
          status: "REJECT"
        }
        await this.rejectOrAgree(this.listBox.memberId, data)
        //重新调取用户接口并赋值

        this.remarkDetail = false
        this.rejectedShow = false

      } else {
        this.remarkDetail = true
      }
    },
    //通过
    agreedBtn(){
      let data = {
        status: "AGREED"
      }
      this.rejectOrAgree(this.listBox.memberId,data)
    },
    //驳回通过调用接口
    async rejectOrAgree(id, data) {
      const response = await putAudit(id, data)
      if(response){
        this.$message.success('操作成功~')
      }
    },
    handleCancel(){
      this.remarkDetail = false
      this.rejectedShow = false
    }
  }
}
</script>
